<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card :loading="loading" :disabled="loading" loader-height="2">
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
             
              <lb-string
                label="Search"
                outlined
                hidedetails
                v-model="seachList"
              ></lb-string>
              <v-spacer></v-spacer>
              <!-- <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              > -->
              <v-btn icon small class="ml-2" @click="refreshData()"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-card-text>
            <v-simple-table class="pbc-view-table" dense>
              <thead>
                <tr class="">
                  <th scope="#" class="px-4 text-center">#</th>

                  <th scope="Code">Code</th>
                  <th scope="Description">Description</th>
                  <th scope="Data Type">Data Type</th>
                  <th scope="Defult Value">Master Default</th>
                  <th scope="Defult Value">Client Default</th>
                  <th scope="Defult Value">Verification</th>
                  <th scope="Status">Alias</th>
                  <th scope="Action">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(v, k) in searchResult">
                  <tr :key="k">
                    <td
                      :class="`text-center ${
                        v.status
                          ? 'border-left-transparent'
                          : 'border-left-error'
                      }`"
                    >
                      <span>{{ k + 1 }}</span>
                    </td>
                    <td>
                      <div class="d-flex align-center">
                        <span
                          v-html="$nova.getHighlitedText(v.code || '', seachList)"
                        ></span>
                        <v-spacer />
                        <v-tooltip left content-class="tooltip-left" v-if="v.hidden">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="error">mdi-circle-medium</v-icon>
                          </template>
                          <span>Hidden within project</span>
                        </v-tooltip>
                      </div>

                    </td>
                    <td>
                      <span>{{ v.name }}</span>
                    </td>
                    <td>
                      <span>{{ v.type }}</span>
                    </td>

                    <td>
                      <span>{{ v.value }}</span>
                    </td>
                    <td>
                      <span>{{ v.inputparameterconfig }}</span>
                    </td>
                    <td>
                      <span>
                        <div v-for="(k, h) in v.verification" :key="h">
                          <v-chip label x-small>{{ k.code }}</v-chip>
                        </div>
                      </span>
                    </td>
                    <td>{{ v.alias }}</td>

                    <td>
                      <v-btn
                        @click="viewConfig(v)"
                        class="view-on-hover-item-d mx-0 px-0"
                        icon
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="configDilog" max-width="600" persistent>
      <v-card>
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text">Input Parameters Config</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="configDilog = false" class="ml-2 white--text">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>

        <v-card class="shadow-off">
          <v-card-text class="">
            <div class="mb-2">
              <div class="d-flex align-center mb-2">
                <span class="font-weight-bold mr-3">Code:</span>
                <span>{{ configObj.code }}</span>
              </div>

              <div class="d-flex align-center mb-2">
                <span class="font-weight-bold mr-3">Name:</span>
                <span>{{ configObj.name }}</span>
              </div>
              <div class="d-flex align-center mb-5">
                <span class="font-weight-bold mr-3">Date Type:</span>
                <span>{{ configObj.type }}</span>
              </div>
              <div class="d-flex align-center mb-5">
                <span class="font-weight-bold mr-5 d-flex"> Defult Value:</span>
                <span>{{ configObj.value }}</span>
              </div>
              <div class="d-flex align-center mb-5">
                <span class="font-weight-bold mr-5 d-flex">Value:</span>

                <lb-number
                  v-if="type === 'number'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-date
                  v-else-if="type === 'date'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-month
                  v-else-if="type === 'month'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-year
                  v-else-if="type === 'year'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-time
                  v-else-if="type === 'time'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-daterange
                  v-else-if="type === 'daterange'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-timerange
                  v-else-if="type === 'timerange'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-list
                  type="stringarray"
                  v-else-if="type === 'dropdown'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-list
                  type="daterangearray"
                  v-else-if="type === 'daterangearray'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-list
                  type="timerangearray"
                  v-else-if="type === 'timerangearray'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-list
                  type="numberarray"
                  v-else-if="type === 'numberarray'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-list
                  type="stringarray"
                  v-else-if="type === 'stringarray'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-list
                  type="datearray"
                  v-else-if="type === 'datearray'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-list
                  type="montharray"
                  v-else-if="type === 'montharray'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-list
                  type="yeararray"
                  v-else-if="type === 'yeararray'"
                  label="Client Value"
                  v-model="clientValue"
                />
                <lb-list
                  type="timearray"
                  v-else-if="type === 'timearray'"
                  label="Client Value"
                  v-model="clientValue"
                />

                <lb-string v-else label="Client Value" v-model="clientValue" />
              </div>
            </div>
          </v-card-text>
          <v-card-text class="d-flex">
            <v-spacer></v-spacer>

            <v-btn
              small
              color="primary"
              v-if="editInputID"
              @click="updateClientValue(configObj._id)"
              >Update</v-btn
            >
            <v-btn
              small
              color="primary"
              v-else
              @click="addClientValue(configObj._id)"
              >Add</v-btn
            >
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      seachList: null,
      Inputdialog: false,
      inputObj: {},
      type: "",
      typeEdit: null,
      clientValue: null,
      typeArr: [
        { name: "Number", value: "number" },

        { name: "String", value: "string" },

        { name: "Date", value: "date" },

        { name: "Month", value: "month" },

        { name: "Year", value: "year" },

        { name: "Time", value: "time" },

        { name: "Date Range", value: "daterange" },

        { name: "Time Range", value: "timerange" },

        { name: "Number Array", value: "numberarray" },

        { name: "String Array", value: "stringarray" },

        { name: "Date Array", value: "datearray" },

        { name: "Month Array", value: "montharray" },

        { name: "Year Array", value: "yeararray" },

        { name: "Time Array", value: "timearray" },

        { name: "Date Range Array", value: "daterangearray" },

        { name: "Time Range Array", value: "timerangearray" },

        { name: "Number Dropdown", value: "numberdropdown" },

        { name: "String Dropdown", value: "stringdropdown" },

        { name: "Date Dropdown", value: "datedropdown" },

        { name: "Month dropdown", value: "monthdropdown" },

        { name: "Year Dropdown", value: "yeardropdown" },

        { name: "Time Dropdown", value: "timedropdown" },

        { name: "Date Range Dropdown", value: "daterangedropdown" },

        { name: "Time Range Dropdown", value: "timerangedropdown" },
      ],
      dropdowntypes: [
        "numberdropdown",
        "stringdropdown",
        "datedropdown",
        "monthdropdown",
        "yeardropdown",
        "timedropdown",
        "daterangedropdown",
        "timerangedropdown",
      ],
      statusArr: [
        { _id: 1, name: "Open" },
        { _id: 0, name: "Close" },
      ],
      flogArr: [
        { _id: 1, name: "Admin Only" },
        { _id: 0, name: "Hidden" },
      ],
      inputParamList: null,
      editInputID: null,
      errors: {},
      possiblevaluelist: [],
      configDilog: false,
      configObj: {},
      addConfig: null,
    };
  },
  props: {
    verificationlist: {
      type: Array,
      required: false,
      default: ()=>[]
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  computed: {
    searchResult() {
      let tempList = this.inputParamList;
      if (this.seachList != "" && this.seachList) {
        tempList = tempList.filter((item) => {
          return item.code.toUpperCase().includes(this.seachList.toUpperCase());
        });
      }

      return tempList;
    },
  },
  methods: {
    refreshData() {
      this.loading = true;
      let ndt = {};
      if(this.verificationlist.length > 0) ndt = {filter: {verificationlist: this.verificationlist}};
      this.axios
        .post("/v2/conserve/inputparameterconfig/get", ndt)
        .then((dt) => {
          if (dt.data.status === "success") {
            // console.log(dt.data.data);
            this.inputParamList = dt.data.data;
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewConfig(item) {
      // console.log(item, "configObj._id");
      this.configObj = JSON.parse(JSON.stringify({ ...item }));
      this.editInputID = this.configObj.inputparameterconfig;
      this.clientValue = this.configObj.inputparameterconfig;
      this.configDilog = true;
    },
    addClientValue(id) {
      let obj = { value: this.clientValue, parameter: id };
      // console.log(obj);
      this.loading = true;
      this.axios
        .post("/v2/conserve/inputparameterconfig/add/", { data: obj })
        .then((dt) => {
          if (dt.data.status === "success") {
            // console.log(dt.data.data);
            this.configDilog = false;
            this.refreshData();
          } else throw new Error(dt.data.message || "Unknown error")
            // console.log(dt.data);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateClientValue(id) {
      let obj = { value: this.clientValue };
      this.loading = true;
      this.axios
        .post("/v2/conserve/inputparameterconfig/edit/" + id, { data: obj })
        .then((dt) => {
          if (dt.data.status === "success") {
            // console.log(dt.data.data);
            this.configDilog = false;
            this.$store.commit("sbSuccess", "update successfully");
            this.refreshData();
          } else throw new Error(dt.data.message || "Error Update Input Parameters Config");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    "configObj.type": function (d) {
      if (d == "number") {
        this.type = d;
        this.clientValue = 0.0;
      }
      this.type = d;
    },
  },
};
</script>
